<template>
  <div class="mobile" v-if="isMobile && hasAnyMobile">
    <DynamicLinker :item="bannersMobile[0]" class="banner banner1">
      <img :src="bannersMobile[0].picture" />
    </DynamicLinker>
    <DynamicLinker :item="bannersMobile[1]" class="banner banner2">
      <img :src="bannersMobile[1].picture" />
    </DynamicLinker>
    <DynamicLinker :item="bannersMobile[2]" class="banner banner3">
      <img :src="bannersMobile[2].picture" />
    </DynamicLinker>
    <DynamicLinker :item="bannersMobile[3]" class="banner banner4">
      <img :src="bannersMobile[3].picture" />
    </DynamicLinker>
  </div>
  <div class="desktop mt-4" v-else-if="!isMobile && hasAnyDesktop">
    <DynamicLinker :item="bannersDesktop[0]" class="banner banner1">
      <img @load="onResize" ref="firstbanner" :src="bannersDesktop[0].picture" />
    </DynamicLinker>
    <div :style="'height:' + desktopHeight" class="desktop2">
      <DynamicLinker :item="bannersDesktop[1]" class="banner banner2">
        <img :src="bannersDesktop[1].picture" />
      </DynamicLinker>
      <DynamicLinker :item="bannersDesktop[2]" class="banner banner3">
        <img :src="bannersDesktop[2].picture" />
      </DynamicLinker>
    </div>
    <DynamicLinker :item="bannersDesktop[3]" class="banner banner4">
      <img :src="bannersDesktop[3].picture" />
    </DynamicLinker>
  </div>
</template>

<script>
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker";
export default {
  name: "FourBanners",
  components: {DynamicLinker},
  inject: ['mediaQueries'],
  data() {
    return {
      desktopHeight: 'auto'
    }
  },
  mounted() {
    this.$store.commit('front/addHomeRetrievedEvent', () => {
      this.onResize()
      document.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    document.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      const banner = this.$refs.firstbanner
      if (!banner) return;
      const height = banner.getBoundingClientRect().height
      console.log(height)
      if (height > 10) {
        this.desktopHeight = (height + 20) + 'px'
      }
    }
  },
  computed: {
    bannersDesktop(){
      if(this.$store.getters['front/getHomeData'] != null) {
        let advertise = this.$store.getters['front/getHomeData'].advertise
        return [
          advertise.desktop_1 , advertise.desktop_2,
          advertise.desktop_3 , advertise.desktop_4
        ]
      } else {
        return false
      }
    },
    bannersMobile(){
      if(this.$store.getters['front/getHomeData'] != null) {
        let advertise = this.$store.getters['front/getHomeData'].advertise
        return [
          advertise.mobile_1 , advertise.mobile_2,
          advertise.mobile_3 , advertise.mobile_4
        ]
      } else {
        return false
      }
    },
    hasAnyDesktop() {
      return this.bannersDesktop && this.bannersDesktop[0] && this.bannersDesktop[1] && this.bannersDesktop[2] && this.bannersDesktop[3]
    },
    hasAnyMobile() {
      return this.bannersMobile && this.bannersMobile[0] && this.bannersMobile[1] && this.bannersMobile[2] && this.bannersMobile[3]
    },
    isMobile() {
      return this.mediaQueries.mobileSize
    },
  }
}
</script>

<style scoped>
.desktop {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.mobile {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: flex-start;
}

.banner {
  background: #333;
  display: inline-block;
}

.mobile .banner img {
  height: auto;
  max-height: 100%;
}

.desktop2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.desktop .banner1 {
  width: 300px;
  height: 520px;
  overflow: hidden;
}

.desktop .banner2 {
  width: 300px;
  height: 250px;
}

.desktop .banner3 {
  width: 300px;
  height: 250px;
}

.desktop .banner4 {
  width: 600px;
  height: 520px;
  overflow: hidden;
}

img {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}
</style>
