<template>
  <svg
                  width="11"
                  height="11"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M19 8.5L12 15.5L5 8.5"
                    stroke="#444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>