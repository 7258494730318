<template>
  <svg width="20" height="16" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 1.44444L8 0.555559C8 0.250002 7.66102 -1.48174e-08 7.2467 -3.29276e-08L0.753296 -3.16763e-07C0.338983 -3.34874e-07 -1.09279e-08 0.250001 -2.42843e-08 0.555559L-6.31385e-08 1.44444C-7.64949e-08 1.75 0.338983 2 0.753296 2L7.2467 2C7.66102 2 8 1.75 8 1.44444Z" fill="#000"/>
<path d="M16 9.44444L16 8.55556C16 8.25 15.661 8 15.2467 8L8.7533 8C8.33898 8 8 8.25 8 8.55556L8 9.44444C8 9.75 8.33898 10 8.7533 10L15.2467 10C15.661 10 16 9.75 16 9.44444Z" fill="#000"/>
<path d="M16 4.55556L16 5.44444C16 5.75 15.5765 6 15.0588 6L0.941176 6C0.42353 6 -1.09279e-08 5.75 -2.42843e-08 5.44444L-6.31385e-08 4.55556C-7.64949e-08 4.25 0.42353 4 0.941176 4L15.0588 4C15.5765 4 16 4.25 16 4.55556Z" fill="#000"/>
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>